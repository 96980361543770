import helpers from '@/helpers';
import {cookieManager} from '@skiwo/sk-basic-components';

export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      disabledImportantFields: false,
      isCopy: false,
      copiedJob: '',
      copiedBy: 'no_reason',
      forceCreate: false,
      job: {
        job_id: '',
        status: '',
        interpretation_requirement: {
          interpreter_uid: '',
          direct_award: false,
        }
      },
      trackType: '',
      existingEditJobInfo: false,
      editingJob: false,
      urlEditingJob: ''
    },
    getters: {
      getDuration(state, getters, rootState) {
        const {startTime} = rootState.PostingStore.DateTimeStore;
        const {finishTime} = rootState.PostingStore.DateTimeStore;
        let duration = moment(finishTime)
          .diff(moment(startTime), 'hours', true);
        duration = Math.round(duration * 100) / 100;
        const durationInt = +String(duration)
          .split('.')[0];
        if (duration < durationInt + 0.17) { // point 10min / 60min = 0.17
          duration = durationInt;
        } else if (duration < durationInt + 0.67) { // point 40min / 60min = 0.67
          duration = durationInt + 0.5;
        } else {
          duration = durationInt + 1;
        }
        return duration;
      },
      getJobDuration(state, getters, rootState) {
        const {startTime} = rootState.PostingStore.DateTimeStore;
        const {finishTime} = rootState.PostingStore.DateTimeStore;
        return moment(finishTime)
          .diff(moment(startTime), 'hours', true);
      },
      getDirectAward(state) {
        return state.job.interpretation_requirement.direct_award;
      },
      bookingFormProgress(state, getters, rootState) {
        const {departmentsProgress, attendersProgress} = rootState.PostingStore.DepartmentAttendersStore;
        return departmentsProgress || attendersProgress;
      }
    },
    actions: {
      createFormPartially({rootState, dispatch}, fields = {}) {
        return new Promise((resolve) => {
          dispatch('createForm').then((form) => {
            const storeJobObj = rootState.OneAssignmentStore.storeJobObj || {};
            const storeJobInfo = storeJobObj?.info || {};
            const storeJobInterpretationRequirement = storeJobObj?.interpretationRequirement || {};
            const storeJobCategory = storeJobObj?.info.jobCategoryItem.jobCategory.id || {};
            const getFieldValue = (field, initialFieldValue) => {
              return field !== undefined ? field : initialFieldValue;
            };

            const paymentMethodId = getFieldValue(fields.paymentMethodId, storeJobObj.paymentMethod?.id);
            const actualSessionType = getFieldValue(fields.actualSessionType, storeJobInterpretationRequirement.actualSessionType);
            const jobCategory = getFieldValue(fields.jobCategory, storeJobCategory.jobCategory);

            if (paymentMethodId) form.append('job[payment_method_id]', paymentMethodId);
            if (actualSessionType) form.append('job[interpretation_requirement][actual_session_type]', actualSessionType);
            if (jobCategory) form.append('job[info][job_category_item_id]', jobCategory);

            form.append('job[info][booking_reference]', getFieldValue(fields.bookingRef, storeJobInfo.bookingReference));
            form.append('job[info][payment_booking_reference]', getFieldValue(fields.paymentBookingRef, storeJobInfo.paymentBookingReference));
            form.append('job[info][case_number]', getFieldValue(fields.caseNumber, storeJobInfo.caseNumber));
            form.append('job[info][client_name]', getFieldValue(fields.clientName, storeJobInfo.clientName));
            form.append('job[info][client_id]', getFieldValue(fields.clientId, storeJobInfo.clientId));
            form.append('job[actual_created_at]', getFieldValue(fields.actualCreatedAt, storeJobObj.actualCreatedAt));
            form.append('job[booking_mechanism]', getFieldValue(fields.bookingMechanism, storeJobObj.bookingMechanism));
            form.append('job[info][manual_booking_fee_applied]', getFieldValue(fields.manualBookingFeeApplied, storeJobInfo.manualBookingFeeApplied));
            form.append('job[info][manual_editing_fee_applied]', getFieldValue(fields.manualEditingFeeApplied, storeJobInfo.manualEditingFeeApplied));
            form.append('job[info][manual_travel_booking_fee_applied]', getFieldValue(fields.manualTravelBookingFeeApplied, storeJobInfo.manualTravelBookingFeeApplied));

            resolve(form);
          });
        });
      },
      createForm({rootGetters, rootState, state, commit, dispatch}) {
        return new Promise((resolve) => {
          const form = new FormData();
          const {assignmentType} = rootState.PostingStore.AssignmentTypeStore;
          const {autoUpdateProcessingType} = rootState.PostingStore.ProcessRequirementStore;
          const isManager = rootGetters['UserInfoStore/userIsManager'];
          const creationIdempotencyKey = helpers.numbers.getRandomString({length: 64});

          // JOB CREATION Idempotency KEY
          if (!state.job.job_id) form.append('job[creation_idempotency_key]', creationIdempotencyKey);

          // EDIT ASSIGNMENT
          if (state.job.job_id) {
            form.append('job[id]', state.job.job_id);
            form.append('job[change_supplier]', !rootState.TTAuthStore.godmode);
            form.append('job[track_type]', state.trackType || 'job_updated');
          }
          if (state.copiedBy === 'dangerous_edit' && rootState.OneAssignmentStore.storeJobObj?.awardedInterpreterUid) {
            form.append('job[interpreter_uid]', rootState.OneAssignmentStore.storeJobObj?.awardedInterpreterUid);
          }
          // PRICING TEMPLATES SECTION
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PricingTemplatesStore/getDemanderInternalTemplate']) {
            form.append('job[demander_internal_pricing_template_id]', rootGetters['PricingTemplatesStore/getDemanderInternalTemplate']);
          }
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PricingTemplatesStore/getDemanderExternalTemplate']) {
            form.append('job[demander_external_pricing_template_id]', rootGetters['PricingTemplatesStore/getDemanderExternalTemplate']);
          }
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PricingTemplatesStore/getSupplierHonorarTemplate']) {
            form.append('job[supplier_honorar_pricing_template_id]', rootGetters['PricingTemplatesStore/getSupplierHonorarTemplate']);
          }
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PricingTemplatesStore/getSupplierCompanyTemplate']) {
            form.append('job[supplier_company_pricing_template_id]', rootGetters['PricingTemplatesStore/getSupplierCompanyTemplate']);
          }
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PricingTemplatesStore/getSupplierEmployeeTemplate']) {
            form.append('job[supplier_employee_pricing_template_id]', rootGetters['PricingTemplatesStore/getSupplierEmployeeTemplate']);
          }
          // MY INFORMATION SECTION
          if (rootState.PostingStore.BookerStore.booker) {
            form.append('job[interpretation_requirement][booker_uid]', rootState.PostingStore.BookerStore.booker);
          }
          const bookingRef = rootState.PostingStore.BookingRefStore.bookingReference;
          const whoFillBookingRef = rootState.PostingStore.BookingRefStore.fromBookingReference;
          form.append('job[info][booking_reference]', bookingRef);
          form.append('job[info][booking_reference_from]', whoFillBookingRef);
          const whoFillPaymentBookingRef = rootState.PostingStore.BookingRefStore.fromPaymentBookingReference;
          form.append('job[info][payment_booking_reference_from]', whoFillPaymentBookingRef);
          if (rootGetters['UserInfoStore/defaultBookingReference']
            && bookingRef
            && rootGetters['UserInfoStore/defaultBookingReference'] !== bookingRef) {
            commit('UserInfoStore/setUserInfo', {userInfoObj: {defaultBookingReference: bookingRef}}, {root: true});
          }
          form.append('job[info][payment_booking_reference]', rootState.PostingStore.BookingRefStore.paymentBookingReference);
          if (rootState.PostingStore.OwnerRealNameStore.ownerRealName && rootGetters['PostingNotifierStore/showOwnerRealNameSection']) {
            form.append('job[info][real_person_name]', rootState.PostingStore.OwnerRealNameStore.ownerRealName);
          }
          if (rootState.PostingStore.DepartmentAttendersStore.department) {
            form.append('job[interpretation_requirement][department_id]', rootState.PostingStore.DepartmentAttendersStore.department);
          }
          const {attenders} = rootState.PostingStore.DepartmentAttendersStore;
          if (attenders && attenders.length) {
            for (const att of attenders) {
              form.append('job[participants][][uid]', att);
            }
          }
          form.append('job[info][case_number]', rootState.PostingStore.CaseNumberStore.caseNumber);
          form.append('job[info][client_name]', rootState.PostingStore.ClientStore.clientName);
          form.append('job[info][client_id]', rootState.PostingStore.ClientStore.clientId);
          form.append('job[info][allow_to_edit_booking_reference_on_posting]', rootGetters['UserInfoStore/allowToEditBookingRefOnPosting']);
          form.append('job[info][allow_to_edit_payment_booking_reference_on_posting]', rootGetters['UserInfoStore/allowToEditPaymentBookingRefOnPosting']);
          if (rootState.PostingStore.PaymentMethodStore.paymentMethod) {
            form.append('job[payment_method_id]', rootState.PostingStore.PaymentMethodStore.paymentMethod);
          }
          if (rootGetters['PostingNotifierStore/showConfirmationsSection']) {
            form.append('job[info][confirmation_by_phone]', !!rootState.PostingStore.ConfirmationByStore.confirmationByPhoneCheckbox);
          }
          // ASSIGNMENT INFORMATION SECTION
          const {languageFrom, languageTo, alternativeLanguageToSelect, alternativeLanguageTo} = rootState.PostingStore.LanguagesStore;
          form.append('job[interpretation_requirement][language_from_id]', languageFrom);
          form.append('job[interpretation_requirement][language_to_id]', languageTo);
          form.append('job[interpretation_requirement][dialect]', rootState.PostingStore.DialectStore.dialect);
          if (alternativeLanguageToSelect && alternativeLanguageTo) {
            form.append('job[interpretation_requirement][alternative_language_to_id]', rootState.PostingStore.LanguagesStore.alternativeLanguageTo);
            form.append('job[process_requirement][alternative_language_processing]', 'alternative_language_auto_update');
          } else {
            form.append('job[process_requirement][alternative_language_processing]', 'alternative_language_no_action');
          }
          const {multipleDateTimesSelect, dateTimes} = rootState.PostingStore.MultipleDateTimeStore;
          if (multipleDateTimesSelect && dateTimes && dateTimes.length) {
            form.append('job[process_requirement][series_job_processing]', rootState.PostingStore.ProcessRequirementStore.seriesJobProcessing || 'different_int');
            form.append('job[series_order_dates][][start_time]', rootState.PostingStore.DateTimeStore.startTime);
            form.append('job[series_order_dates][][finish_time]', rootState.PostingStore.DateTimeStore.finishTime);
            for (const dateTime of dateTimes) {
              form.append('job[series_order_dates][][start_time]', dateTime.startTime);
              form.append('job[series_order_dates][][finish_time]', dateTime.finishTime);
            }
          }
          form.append('job[interpretation_requirement][start_time]', rootState.PostingStore.DateTimeStore.startTime);
          form.append('job[interpretation_requirement][finish_time]', rootState.PostingStore.DateTimeStore.finishTime);
          const {category} = rootState.PostingStore.SubjectStore;
          if (rootGetters['PostingStore/PaymentMethodStore/showCategoriesSection'] || rootGetters['OneAssignmentStore/isVatOverride']) {
            if (category) {
              form.append('job[info][job_category_item_id]', category);
            }
            form.append('job[info][subject]', rootState.PostingStore.SubjectStore.subject);
          }
          form.append('job[interpretation_requirement][session_type]', rootState.PostingStore.AssignmentTypeStore.assignmentType);
          if (rootState.PostingStore.AssignmentTypeStore.actualAssignmentType) {
            form.append('job[interpretation_requirement][actual_session_type]', rootState.PostingStore.AssignmentTypeStore.actualAssignmentType);
          }
          const {blockedSuppliers} = rootState.PostingStore.BlockedSuppliersStore;
          if (blockedSuppliers && blockedSuppliers.length) {
            for (const supplier of blockedSuppliers) {
              form.append('job[blocked_interpreters][][uid]', supplier);
            }
          }
          const {blockedCitiesFullList} = rootState.PostingStore.BlockedCitiesStore;
          if (blockedCitiesFullList && blockedCitiesFullList.length) {
            for (const city of blockedCitiesFullList) {
              form.append('job[interpretation_requirement][blocked_cities][][country]', city.country);
              form.append('job[interpretation_requirement][blocked_cities][][county]', city.county);
              form.append('job[interpretation_requirement][blocked_cities][][name]', city.city);
              form.append('job[interpretation_requirement][blocked_cities][][latitude]', city.latitude);
              form.append('job[interpretation_requirement][blocked_cities][][longitude]', city.longitude);
            }
          }
          const confirmationBy = rootState.PostingStore.ConfirmationByStore.deadline;
          if (confirmationBy) form.append('job[interpretation_requirement][confirmation_by]', confirmationBy);

          // ADVANCED SETTINGS
          const {attachments} = rootState.PostingStore.AttachmentsStore;
          if (attachments && attachments.length) {
            for (const file of attachments) {
              if (!file.id) {
                form.append('job[attachments][][file]', file.file);
                form.append('job[attachments][][comment]', file.name);
              } else if (file.deleted) {
                form.append('job[attachments][][id]', file.id);
                form.append('job[attachments][][deleted]', true);
              }
            }
          }
          const {participantEmails} = rootState.PostingStore.ParticipantsStore;
          if (participantEmails && participantEmails.length) {
            for (const email of participantEmails) {
              form.append('job[info][cc_emails][][email]', email.email);
            }
          } else {
            form.append('job[info][cc_emails]', []);
          }
          const {participantPhones} = rootState.PostingStore.ParticipantsStore;
          if (participantPhones && participantPhones.length) {
            for (const phone of participantPhones) {
              form.append('job[info][confirmation_phones][]', phone);
            }
          }
          const qualifications = rootState.selectsData.qualification || [];
          const lastQualification = qualifications.length ? qualifications[qualifications.length - 1].id : '';
          const qualification = rootState.PostingStore.QualificationStore.qualification || rootGetters['UserInfoStore/userDefaultQualificationLevel'] || lastQualification;
          form.append('job[interpretation_requirement][qualification_id]', qualification);
          const specificQualificationRequested = !!(rootState.PostingStore.QualificationStore.qualificationSelect && rootState.PostingStore.QualificationStore.qualification);
          form.append('job[interpretation_requirement][specific_qualification_requested]', specificQualificationRequested);
          form.append('job[interpretation_requirement][sex]', rootState.PostingStore.GenderStore.sex); // send 2 for select male and female or for edit
          form.append('job[info][description]', rootState.PostingStore.ExtraInfoStore.extraInfo);
          form.append('job[accessibility]', state.job.interpretation_requirement.interpreter_uid ? 'private' : rootState.PostingStore.AccessibilityStore.accessibility);
          if ((rootState.TTAuthStore.godmode || isManager) && rootGetters['PostingStore/ReplacementStore/actualCreatedAt']) {
            form.append('job[actual_created_at]', rootGetters['PostingStore/ReplacementStore/actualCreatedAt']);
            form.append('job[booking_mechanism]', rootState.PostingStore.ReplacementStore.bookingMechanism || 'platform');
          }
          if (rootState.TTAuthStore.godmode || isManager) {
            form.append('job[info][manual_booking_fee_applied]', rootState.PostingStore.ManualFeesStore.manualFees.booking);
            form.append('job[info][manual_editing_fee_applied]', rootState.PostingStore.ManualFeesStore.manualFees.editing);
            form.append('job[info][manual_travel_booking_fee_applied]', rootState.PostingStore.ManualFeesStore.manualFees.travelBooking);
          }
          // PRICE REQUIREMENTS
          if (rootGetters['PostingNotifierStore/showAutoApproveSection']) {
            form.append('job[allow_auto_award]', rootState.PostingStore.AutoApproveStore.autoApprove);
          }

          // PROCESS REQUIREMENTS
          if (rootGetters['PostingNotifierStore/showStandBySection']) {
            form.append('job[process_requirement][standby_processing]', rootGetters['PostingStore/StandByStore/standByValue']);
          }

          if (rootGetters['PostingNotifierStore/showAutoInviteSection']) {
            const isDirectBooking = !!state.job.interpretation_requirement.interpreter_uid;
            const regularAutoInviteValue = rootState.PostingStore.AutoInviteStore.autoInvite
              ? 'admin_auto_invite_start'
              : 'admin_auto_invite_stop';
            const adminAutoInviteProcessing = isDirectBooking ? 'admin_auto_invite_no_action' : regularAutoInviteValue;

            if (regularAutoInviteValue) form.append('job[process_requirement][infinite_auto_invite]', !!rootState.PostingStore.AutoInviteStore.infiniteAutoInvite);
            form.append('job[process_requirement][admin_auto_invite_processing]', adminAutoInviteProcessing);
          }

          if (rootGetters['PostingNotifierStore/showSessionTypeProcessingSection'] && rootState.PostingStore.ProcessRequirementStore.sessionTypeProcessing) {
            form.append('job[process_requirement][session_type_processing]', rootState.PostingStore.ProcessRequirementStore.sessionTypeProcessing);
          }
          if (rootState.PostingStore.ProcessRequirementStore.sessionTypeProcessing && rootState.PostingStore.ProcessRequirementStore.sessionTypeProcessing !== '' && rootState.PostingStore.ProcessRequirementStore.autoUpdateToSessionType) {
            form.append('job[process_requirement][auto_update_to_session_type]', rootState.PostingStore.ProcessRequirementStore.autoUpdateToSessionType);
          }

          if (rootGetters['PostingNotifierStore/showGenderProcessingSection'] && rootState.PostingStore.ProcessRequirementStore.genderProcessing) {
            form.append('job[process_requirement][gender_processing]', rootState.PostingStore.ProcessRequirementStore.genderProcessing);
          }

          if (rootGetters['PostingNotifierStore/showQualificationProcessingSection'] && rootState.PostingStore.ProcessRequirementStore.qualificationProcessing) {
            form.append('job[process_requirement][qualification_processing]', rootState.PostingStore.ProcessRequirementStore.qualificationProcessing);
          } else {
            // if the user doesn't need a specific qualification level
            form.append('job[process_requirement][qualification_processing]', 'qualification_auto_update');
          }

          if (rootGetters['PostingNotifierStore/showDirectProcessingSection'] && rootState.PostingStore.ProcessRequirementStore.directProcessing) {
            form.append('job[process_requirement][direct_processing]', rootState.PostingStore.ProcessRequirementStore.directProcessing);
          }

          // COPIED JOB
          if (state.copiedJob) {
            form.append('job[original_job_id]', state.copiedJob);
            form.append('job[copied_by]', state.copiedBy);
          }

          // PARA FOR PUNCHOUT BOOKING
          if (cookieManager.getCookie('punch_out_redirect_url')) {
            form.append('job[punch_out_redirect_url]', cookieManager.getCookie('punch_out_redirect_url'));
          }

          // DIRECT BOOKING
          if (state.job.interpretation_requirement.interpreter_uid) {
            form.append('job[interpreter_uid]', state.job.interpretation_requirement.interpreter_uid);
            form.append('job[direct_award]', state.job.interpretation_requirement.direct_award);
          }

          // PARAMS FOR OPEN BOOKING
          const openBookingObj = rootState.OpenBookingStore.openBooking;
          if (openBookingObj.openBookingType) {
            form.append('job[page_name]', openBookingObj.openBookingType);
            form.append('job[booker][first_name]', openBookingObj.firstName.trim());
            form.append('job[booker][last_name]', openBookingObj.lastName.trim());
            form.append('job[booker][phone_code]', openBookingObj.phoneCode);
            form.append('job[booker][phone_number]', openBookingObj.phoneNumber.trim());
            form.append('job[booker][email]', openBookingObj.email.trim());
            form.append('job[booker][existing_user]', !!openBookingObj.isExistingCustomer);
            if (openBookingObj.organization && openBookingObj.organization !== 'other') form.append('job[booker][enterprise_id]', openBookingObj.organization);
            if (openBookingObj.orgNumber) form.append('job[booker][org_number]', openBookingObj.orgNumber);
            if (openBookingObj.department) form.append('job[interpretation_requirement][department_id]', openBookingObj.department);
            if (openBookingObj.bookingRef) form.append('job[info][booking_reference]', openBookingObj.bookingRef.trim());
            if (openBookingObj.paymentBookingRef) form.append('job[info][payment_booking_reference]', openBookingObj.paymentBookingRef.trim());
            if (openBookingObj.caseNumber) form.append('job[info][case_number]', openBookingObj.caseNumber.trim());
            if (openBookingObj.isExistingCustomer && openBookingObj.paymentCompany === 'someone_else' && openBookingObj.paymentOrgNumber) {
              form.append('job[payment_org_number]', openBookingObj.paymentOrgNumber);
            }
            if (!openBookingObj.isExistingCustomer) {
              if (openBookingObj.paymentCompany === 'someone_else' && openBookingObj.paymentOrgNumber) {
                form.append('job[payment_org_number]', openBookingObj.paymentOrgNumber);
              }
              if (openBookingObj.paymentCompany === 'my_company' && rootGetters['OpenBookingStore/currentOrgNumber']) {
                form.append('job[payment_org_number]', rootGetters['OpenBookingStore/currentOrgNumber']);
              }
            }
          }
          if (state.forceCreate) {
            form.append('job[force_create]', state.forceCreate);
          }

          // VIDEO FORM
          if (assignmentType === 'video' || autoUpdateProcessingType === 'video') {
            const {videoPlatform} = rootState.PostingStore.AssignmentTypeStore;
            if (videoPlatform.type === 'own') {
              const instructions = videoPlatform.instructions?.trim();
              const url = videoPlatform.url?.trim();
              if (instructions) {
                form.append('job[interpretation_requirement][video_platform_instructions]', instructions);
              }
              if (url) {
                form.append('job[interpretation_requirement][video_platform_url]', url);
              }
              if (videoPlatform.id === 'other') {
                form.append('job[interpretation_requirement][other_video_platform]', true);
              } else {
                form.append('job[interpretation_requirement][video_platform_id]', videoPlatform.id);
              }
            } else {
              const defaultVideoPlatformId = rootGetters['PostingStore/AssignmentTypeStore/defaultVideoPlatformId'];

              form.append('job[interpretation_requirement][video_platform_id]', defaultVideoPlatformId);
            }
          }

          // IN PERSON FORM
          if (assignmentType === 'in_person') {
            const {contactAttributes} = rootState.PostingStore.AssignmentTypeStore;
            if (contactAttributes.person.id) {
              form.append('job[interpretation_requirement][contact_attributes][person][id]', contactAttributes.person.id);
            } else {
              if (contactAttributes.person.name) {
                form.append('job[interpretation_requirement][contact_attributes][person][name]', contactAttributes.person.name);
              }
              if (contactAttributes.person.phone) {
                form.append('job[interpretation_requirement][contact_attributes][person][phone]', contactAttributes.person.phone);
              }
              form.append('job[interpretation_requirement][contact_attributes][person][save_contact]', contactAttributes.person.save_contact);
            }
            if (rootGetters['PostingStore/AssignmentTypeStore/isAddressSelectedFromList']) {
              const chosenAddressData = rootGetters['PostingStore/AssignmentTypeStore/chosenAddressData'];

              form.append('job[interpretation_requirement][contact_attributes][address][latitude]', chosenAddressData.latitude);
              form.append('job[interpretation_requirement][contact_attributes][address][longitude]', chosenAddressData.longitude);
              form.append('job[interpretation_requirement][contact_attributes][address][line_1]', chosenAddressData.line1);
              form.append('job[interpretation_requirement][contact_attributes][address][line_2]', chosenAddressData.line2 || '');
              form.append('job[interpretation_requirement][contact_attributes][address][postcode]', chosenAddressData.postcode);
              form.append('job[interpretation_requirement][contact_attributes][address][city]', chosenAddressData.city);
              form.append('job[interpretation_requirement][contact_attributes][address][county]', chosenAddressData.county);
              form.append('job[interpretation_requirement][contact_attributes][address][country]', chosenAddressData.country);
              form.append('job[interpretation_requirement][contact_attributes][address][extra]', chosenAddressData.extra || '');
              form.append('job[interpretation_requirement][contact_attributes][address][save_address]', false);
            } else {
              const {line1, line2, postcode, city, county, country} = rootState.FullAddressStore;
              const address = rootGetters['FullAddressStore/fullAddress'];
              dispatch('FullAddressStore/getGeometryLocation', address, {root: true})
                .then(() => {
                  const {latitude, longitude} = rootState.FullAddressStore;
                  form.append('job[interpretation_requirement][contact_attributes][address][latitude]', latitude);
                  form.append('job[interpretation_requirement][contact_attributes][address][longitude]', longitude);
                  form.append('job[interpretation_requirement][contact_attributes][address][line_1]', line1);
                  form.append('job[interpretation_requirement][contact_attributes][address][line_2]', line2 || '');
                  form.append('job[interpretation_requirement][contact_attributes][address][postcode]', postcode);
                  form.append('job[interpretation_requirement][contact_attributes][address][city]', city);
                  form.append('job[interpretation_requirement][contact_attributes][address][county]', county);
                  form.append('job[interpretation_requirement][contact_attributes][address][country]', country);
                  form.append('job[interpretation_requirement][contact_attributes][address][save_address]', contactAttributes.address.save_address);
                  form.append('job[interpretation_requirement][contact_attributes][address][extra]', contactAttributes.address.extra || '');

                  resolve(form);
                });
              return;
            }
          }

          resolve(form);
        });
      },
      createJob({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.godmode,
                jobForm: form
              };
              dispatch('postAnAssignment', params, {root: true})
                .then((job) => {
                    resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      editJobPartially({rootState, state, dispatch}, fields = {}) {
        return new Promise((resolve, reject) => {
          dispatch('createFormPartially', fields)
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.support,
                jobForm: form,
                id: state.job.job_id
              };
              dispatch('editAssignment', params, {root: true})
                .then((job) => {
                  resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      editJob({rootState, state, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.godmode,
                jobForm: form,
                id: state.job.job_id
              };
              dispatch('editAssignment', params, {root: true})
                .then((job) => {
                  resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      createLogoutJob({dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              dispatch('postLogoutAssignment', {jobForm: form}, {root: true})
                .then((job) => {
                  resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      getJob({commit, dispatch, rootGetters, rootState}, id) {
        const setNewJob = (newJobObj, resolve) => {
          if (newJobObj.code && newJobObj.code == 401) {
            commit('GlobalStore/set404Page', '', {root: true});
            resolve();
            return;
          }
          dispatch('setJobInfo', {info: newJobObj.job, rootGetters, rootState})
            .then(() => {
              resolve();
            });
        };

        return new Promise((resolve, reject) => {
          fetchData.getAnAssignment(id).then((newJobObj) => {
              setNewJob(newJobObj, resolve, reject);
              resolve();
            }).catch((error) => {
              reject(error);
            });
        });

        // const fallBackRequest = (resolve, reject) => {
        //   fetchData.getAnAssignment(id)
        //     .then((newJobObj) => {
        //       setNewJob(newJobObj, resolve, reject);
        //     })
        //     .catch((error) => {
        //       reject(error);
        //     });
        // };

        // return new Promise((resolve, reject) => {
        //   dispatch('getShortLivedToken', '', {root: true})
        //     .then((res) => {
        //       const params = {token: res.token};
        //       // ------ SSE Request  ------ //
        //       sseRequest(
        //         {
        //           onmessage: (event) => {
        //             const data = JSON.parse(event.data);
        //             if (data) {
        //               setNewJob(data, resolve, reject);
        //             }
        //           },
        //           onerror: () => {
        //             fallBackRequest(resolve, reject);
        //           },
        //           url: `/v1/jobs/${id}/async`,
        //           params
        //         }
        //       );
        //     })
        //     .catch(() => {
        //       fallBackRequest(resolve, reject);
        //     });
        // });
      },
      getCopyJob({commit, dispatch, rootState, rootGetters}, {copyJobId, replacement}) {
        // success handler
        const setNewJob = (newJobObj, resolve) => {
          if (newJobObj.code && newJobObj.code == 401) {
            commit('GlobalStore/set404Page', '', {root: true});
            resolve();
            return;
          }
          const clearPrevJobObj = {
            status: '',
            accessibility: 'private',
            copiedJob: copyJobId
          };
          dispatch('PostInterpretationStore/clearStore', null, {root: true});
          dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', null, {root: true});

          // increase preselect start/end date by one month
          const startTime = new Date(newJobObj.job.interpretationRequirement.startTime);
          const finishTime = new Date(newJobObj.job.interpretationRequirement.finishTime);
          newJobObj.job.interpretationRequirement.startTime = startTime.toISOString();
          newJobObj.job.interpretationRequirement.finishTime = finishTime.toISOString();

          dispatch('setJobInfo', {
            info: {
              ...newJobObj.job,
              ...clearPrevJobObj
            },
            rootGetters,
            rootState
          })
            .then(() => {
              // reset data for copied assignment
              if (!replacement) {
                dispatch('PostingNotifierStore/preselectActualCreatedData', null, {root: true});
                dispatch('PostingNotifierStore/clearActualCreatedTime', null, {root: true});
                commit('PostingStore/AssignmentTypeStore/setVideoPlatformConfirmationValue', false, {root: true});
                commit('PostingStore/AutoInviteStore/clearStore', null, {root: true});
              }

              // check existing category
              dispatch('PostingNotifierStore/checkExistingCategory', null, {root: true});
              resolve();
            });
        };

        return new Promise((resolve, reject) => {
          fetchData.getAnAssignment(copyJobId).then((newJobObj) => {
              setNewJob(newJobObj, resolve, reject);
              resolve();
            }).catch((error) => {
              reject(error);
            });
        });

        // fallback handler
        // const fallBackRequest = (resolve, reject) => {
        //   fetchData.getAnAssignment(copyJobId)
        //     .then((newJobObj) => {
        //       setNewJob(newJobObj, resolve, reject);
        //     })
        //     .catch((error) => {
        //       reject(error);
        //     });
        // };

        // default SSE request
        // return new Promise((resolve, reject) => {
        //   dispatch('getShortLivedToken', '', {root: true})
        //     .then((res) => {
        //       const params = {token: res.token};
        //       // ------ SSE Request  ------ //
        //       sseRequest(
        //         {
        //           onmessage: (event) => {
        //             const data = JSON.parse(event.data);
        //             if (data) {
        //               setNewJob(data, resolve, reject);
        //             }
        //           },
        //           onerror: () => {
        //             fallBackRequest(resolve, reject);
        //           },
        //           url: `/v1/jobs/${copyJobId}/async`,
        //           params
        //         }
        //       );
        //     })
        //     .catch(() => {
        //       fallBackRequest(resolve, reject);
        //     });
        // });
      },
      setJobInfo({commit, dispatch, rootGetters, rootState}, data) {
        return new Promise((resolve) => {
          commit('setJobInfo', data);
          // fetch data for customer quick edit
          if (rootGetters['UserInfoStore/userIsNotInterpreter']) {
            dispatch('PostingNotifierStore/fetchDataForEdit', null, {root: true})
              .then(() => {
                dispatch('PostingNotifierStore/checkCopiedPaymentMethod', null, {root: true});
                if (rootState.PostingStore.AssignmentTypeStore.assignmentType === 'in_person') {
                  rootState.PostingStore.ProcessRequirementStore.autoUpdateToSessionType = data.info.processRequirement.autoUpdateToSessionType;
                }
                // preselect direct interpreter
                const profileData = rootState.EditProfileStore.profileData;
                rootState.PostingStore.DirectBookingStore.suppliersList = [
                  {
                    uid: data.info.directInterUid,
                    name: `${profileData.person?.firstName}  ${profileData.person?.lastName}`,
                    ...profileData
                  }
                ];
                rootState.PostingStore.DirectBookingStore.selectedSupplier = data.info?.directInterUid;
                if (rootState.PostInterpretationStore.job?.interpretation_requirement) {
                  rootState.PostInterpretationStore.job.interpretation_requirement.interpreter_uid = data.info?.directInterUid;
                }
                rootState.PostingStore.ProcessRequirementStore.directProcessing = data.info.processRequirement.directProcessing !== 'direct_no_action' ? data.info.processRequirement.directProcessing : '';

                resolve();
              });
          }
          resolve();
        });
      },
      // DRIVING LICENSE
      // getTrafficStations({commit}) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.getTrafficStations().then((data) => {
      //       if (data.code && data.code == 401) {
      //         commit('GlobalStore/set404Page', '', {root: true});
      //         resolve();
      //         return;
      //       }
      //       commit('setTrafficStationsList', data);
      //       resolve();
      //     }).catch(() => {
      //       reject();
      //     });
      //   });
      // },
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {
          name: state.errors[fieldName].name,
          errors: state.errors[fieldName].errors
        }, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      },
      setCookieJobInfo({commit, rootState}, storageObj) {
        commit('setCookieJobInfo', {storageObj, rootState});
      },
      setJobCopiedBy({commit}, reason) {
        commit('setCopiedBy', reason);
      },
      clearPersonalInfoAndInvoiceStores({commit}) {
        commit('PostingStore/BookerStore/clearStore', null, {root: true});
        commit('PostingStore/DepartmentAttendersStore/clearStore', null, {root: true});
        commit('PostingStore/BookingRefStore/clearStore', null, {root: true});
        commit('PostingStore/CaseNumberStore/clearStore', null, {root: true});
        commit('PostingStore/ClientStore/clearStore', null, {root: true});
        commit('PostingStore/PaymentMethodStore/clearStore', null, {root: true});
        commit('PostingStore/AssignmentTypeStore/clearContactAddresses', null, {root: true});
        commit('PostingStore/OwnerRealNameStore/clearStore', null, {root: true});
        commit('EnterpriseStore/clearStore', null, {root: true});
      },
      clearAssignmentInfoStores({commit}) {
        commit('PostingStore/AttachmentsStore/clearStore', null, {root: true});
        commit('EditProfileStore/clearStore', null, {root: true});
        commit('PostingStore/DeadlineStore/clearStore', null, {root: true});
        commit('PostingStore/ExtraInfoStore/clearStore', null, {root: true});
        commit('PostingStore/LanguagesStore/clearStore', null, {root: true});
        commit('PostingStore/SubjectStore/clearStore', null, {root: true});
        commit('PostingStore/DialectStore/clearStore', null, {root: true});
        commit('PostingStore/DateTimeStore/clearStore', null, {root: true});
        commit('PostingStore/AssignmentTypeStore/clearStore', null, {root: true});
        commit('PostingStore/GenderStore/clearStore', null, {root: true});
        commit('PostingStore/QualificationStore/clearStore', null, {root: true});
        commit('PostingStore/ParticipantsStore/clearStore', null, {root: true});
        commit('PostingStore/AccessibilityStore/clearStore', null, {root: true});
        commit('PostingStore/ProcessRequirementStore/clearStore', null, {root: true});
        commit('FullAddressStore/clearStore', null, {root: true});
        commit('PostingStore/AutoInviteStore/clearStore', null, {root: true});
        commit('PostingStore/AutoApproveStore/clearStore', null, {root: true});
        commit('PostingStore/BlockedSuppliersStore/clearStore', null, {root: true});
        commit('PostingStore/DirectBookingStore/clearStore', null, {root: true});
        commit('PostingStore/BlockedCitiesStore/clearStore', null, {root: true});
        commit('PostingStore/ConfirmationByStore/clearStore', null, {root: true});
        commit('PostingStore/ReplacementStore/clearStore', null, {root: true});
        commit('PostingStore/ManualFeesStore/clearStore', null, {root: true});
        commit('PostingStore/SeveralSuppliersStore/clearStore', null, {root: true});
        commit('PostingStore/MultipleDateTimeStore/clearStore', null, {root: true});
        commit('PostingStore/SuppliersListStore/clearStore', null, {root: true});
        commit('PricingTemplatesStore/clearStore', null, {root: true});
        commit('resetPostJobInfo');
      },
      clearStore({dispatch}) {
        dispatch('clearPersonalInfoAndInvoiceStores');
        dispatch('clearAssignmentInfoStores');
      },
      restoreEditingData({commit}) {
        const savedData = JSON.parse(localStorage.getItem('unfinishedEditingJob'));
        commit('setEditingJob', savedData);
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setEditingJob(state, data) {
        if (data) {
          state.urlEditingJob = data.url || '';
          state.editingJob = data.unfinished;
        }
      },
      setTrackType(state, value) {
        state.trackType = value;
      },
      setInterpreterUid(state, value) {
        state.job.interpretation_requirement.interpreter_uid = value;
      },
      setDirectAward(state, value) {
        state.job.interpretation_requirement.direct_award = value;
      },
      setJobId(state, value) {
        state.job.job_id = value;
      },
      setCopiedBy(state, value) {
        state.copiedBy = value;
      },
      // SET INFO FROM COOKIE
      setCookieJobInfo(state, {storageObj, rootState}) {
        state.job.accessibility = storageObj.accessibility || 'private';
        rootState.PostingStore.LanguagesStore.languageFrom = storageObj.langFrom;
        rootState.PostingStore.LanguagesStore.languageTo = storageObj.langTo;
        rootState.PostingStore.DialectStore.dialect = storageObj.dialect;
        rootState.PostingStore.AssignmentTypeStore.assignmentType = storageObj.assignmentType;
        rootState.PostingStore.QualificationStore.qualification = storageObj.assignmentQualification;
        rootState.PostingStore.DateTimeStore.startTime = moment(storageObj.startTime);
        rootState.PostingStore.DateTimeStore.finishTime = moment(storageObj.finishTime);
        rootState.PostingStore.SubjectStore.subject = storageObj.subject || '';
        rootState.PostingStore.ExtraInfoStore.extraInfo = storageObj.extraInfo || '';
        rootState.PostingStore.CaseNumberStore.caseNumber = storageObj.caseNumber || '';
        rootState.PostingStore.ClientStore.clientName = storageObj.clientName || '';
        rootState.PostingStore.ClientStore.clientId = storageObj.clientId || '';
        rootState.PostingStore.BookingRefStore.bookingReference = storageObj.bookingRef || '';
        rootState.PostingStore.BookingRefStore.paymentBookingReference = storageObj.paymentBookingRef || '';
        rootState.PostingStore.AssignmentTypeStore.contactAttributes.person.name = storageObj.personName || '';
        rootState.PostingStore.AssignmentTypeStore.contactAttributes.person.phone = storageObj.personPhone || '';
        rootState.FullAddressStore.line1 = storageObj.line1 || '';
        rootState.FullAddressStore.line2 = storageObj.line2 || '';
        rootState.FullAddressStore.postcode = storageObj.postcode || '';
        rootState.FullAddressStore.city = storageObj.city || '';
        rootState.FullAddressStore.country = storageObj.country || '';
        rootState.FullAddressStore.county = storageObj.county || '';
        rootState.PostingStore.AssignmentTypeStore.contactAttributes.address.extra = storageObj.wayDescr || '';
        state.job.interpretation_requirement.interpreter_uid = storageObj.uIdForDirect || '';
        rootState.PostingStore.DateTimeStore.customDuration = rootState.PostingStore.DateTimeStore.finishTime.diff(rootState.PostingStore.DateTimeStore.startTime, 'minutes');
      },
      // INFO FOR EDIT PAGE
      setJobInfo(state, {info, rootGetters, rootState}) {
        const userIsNotInterpreter = rootGetters['UserInfoStore/userIsNotInterpreter'];
        const isGodmode = rootGetters['TTAuthStore/isGodmode'];
        const restrictEditingImportantFields = helpers.editAssignmentRestrictions.restrictEditingImportantFields(
          info.status,
          userIsNotInterpreter,
          isGodmode
        );
        // transfer data from localstorage if user edit dangerous fields
        const dangerousEditedData = localStorage.getItem('dangerousFieldsEditedValues');
        const parsedDangerousData = dangerousEditedData ? JSON.parse(dangerousEditedData) : {};

        const languageTo = parsedDangerousData.languageTo ?? info.interpretationRequirement.languageToId;
        const languageFrom = parsedDangerousData.languageFrom ?? info.interpretationRequirement.languageFromId;
        const startTime = parsedDangerousData.startTime ?? info.interpretationRequirement.startTime;
        const finishTime = parsedDangerousData.finishTime ?? info.interpretationRequirement.finishTime;
        const assignmentType = parsedDangerousData.assignmentType ?? info.interpretationRequirement.sessionType;

        state.forceCreate = false;
        state.existingEditJobInfo = true;
        state.job.status = info.status || ''; // status for edit job page
        state.copiedJob = info.copiedJob || '';
        rootState.OneAssignmentStore.storeJobObj = info;
        state.disabledImportantFields = restrictEditingImportantFields || info.shortNoticePeriodStarted;
        // my information block
        rootState.PostingStore.BookerStore.booker = info.interpretationRequirement.bookerUid || rootGetters['UserInfoStore/userUid'];
        rootState.PostingStore.BookingRefStore.bookingReference = info.info.bookingReference || '';
        rootState.PostingStore.BookingRefStore.paymentBookingReference = info.info.paymentBookingReference || '';
        rootState.PostingStore.BookingRefStore.initialBookingReference = info.info.bookingReference || '';
        rootState.PostingStore.BookingRefStore.initialPaymentBookingReference = info.info.paymentBookingReference || '';
        rootState.PostingStore.OwnerRealNameStore.ownerRealName = info.info.owner || '';
        rootState.PostingStore.DepartmentAttendersStore.department = info.interpretationRequirement.department ? info.interpretationRequirement.department.id : '';
        if (info.interpretationRequirement.attenders && info.interpretationRequirement.attenders.length) {
          const res = [];
          for (const att of info.interpretationRequirement.attenders) {
            res.push(att.uid);
          }
          rootState.PostingStore.DepartmentAttendersStore.attenders = res;
        }
        const defaultPaymentMethodId = rootGetters['PostingStore/PaymentMethodStore/defaultPaymentMethodData']?.id || '';
        rootState.PostingStore.PaymentMethodStore.paymentMethod = info.paymentMethod
          ? info.paymentMethod.id || defaultPaymentMethodId
          : defaultPaymentMethodId;
        rootState.PostingStore.CaseNumberStore.caseNumber = info.info.caseNumber || '';
        rootState.PostingStore.ClientStore.clientName = info.info.clientName || '';
        rootState.PostingStore.ClientStore.clientId = info.info.clientId || '';
        // assignment information block
        rootState.PostingStore.LanguagesStore.languageFrom = languageFrom || '3';
        rootState.PostingStore.LanguagesStore.languageTo = languageTo || '';
        rootState.PostingStore.DialectStore.dialect = info.interpretationRequirement.dialect || '';
        const alternativeLanguage = info.interpretationRequirement.alternativeLanguageToId || '';
        rootState.PostingStore.LanguagesStore.alternativeLanguageTo = alternativeLanguage;
        rootState.PostingStore.LanguagesStore.alternativeLanguageToSelect = !!alternativeLanguage;
        rootState.PostingStore.DateTimeStore.startTime = startTime || '';
        rootState.PostingStore.DateTimeStore.finishTime = finishTime || '';
        rootState.PostingStore.DeadlineStore.deadline = info.interpretationRequirement.deadline || '';
        rootState.PostingStore.DateTimeStore.customDuration = moment(rootState.PostingStore.DateTimeStore.finishTime)
          .diff(moment(rootState.PostingStore.DateTimeStore.startTime), 'minutes');
        rootState.PostingStore.SubjectStore.subject = info.info.subject || '';
        rootState.PostingStore.SubjectStore.category = info.info.jobCategoryItem ? info.info.jobCategoryItem.id : '';
        rootState.PostingStore.AssignmentTypeStore.assignmentType = assignmentType || '';
        rootState.PostingStore.AssignmentTypeStore.actualAssignmentType = info.interpretationRequirement.actualSessionType || '';
        const {videoPlatform, otherVideoPlatform, videoPlatformInstructions, videoPlatformUrl} = info.interpretationRequirement;
        rootState.PostingStore.AssignmentTypeStore.videoPlatform.id = videoPlatform?.id || (otherVideoPlatform ? 'other' : '') || '';
        rootState.PostingStore.AssignmentTypeStore.videoPlatform.type = videoPlatform
          ? videoPlatform.default
            ? 'default'
            : 'own'
          : 'default';
        rootState.PostingStore.AssignmentTypeStore.videoPlatform.confirmationValue = !videoPlatform?.default;
        rootState.PostingStore.AssignmentTypeStore.videoPlatform.instructions = videoPlatformInstructions || '';
        rootState.PostingStore.AssignmentTypeStore.videoPlatform.url = videoPlatformUrl || '';
        rootState.PostingStore.GenderStore.sex = info.interpretationRequirement.sex;
        rootState.PostingStore.GenderStore.genderSelect = info.interpretationRequirement.sex == '0' || info.interpretationRequirement.sex == '1';
        if (info.blockedInterpreters && info.blockedInterpreters.length) {
          rootState.PostingStore.BlockedSuppliersStore.blockedSuppliersSelect = true;
          rootState.PostingStore.BlockedSuppliersStore.blockedSuppliers = info.blockedInterpreters.map((inter) => inter.uid);
          rootState.PostingStore.BlockedSuppliersStore.blockedSuppliersFullList = info.blockedInterpreters.map((inter) => {
            return {
              id: inter.uid,
              name: inter.name
            };
          });
        }
        const {blockedCities} = info.interpretationRequirement;
        if (blockedCities?.length) {
          // eslint-disable-next-line no-return-assign
          const fullList = blockedCities.map((cityObj = {}, index) => {
            const {name: city, county, country} = cityObj;
            return {
              ...cityObj,
              ...{id: index, name: `${city}, ${county}, ${country}`, city}
            };
          });
          rootState.PostingStore.BlockedCitiesStore.blockedCitiesSelect = true;
          rootState.PostingStore.BlockedCitiesStore.blockedCitiesFullList = fullList;
          rootState.PostingStore.BlockedCitiesStore.blockedCities = fullList.map((city) => city.id);
        }
        const {ccEmails = []} = info.info;
        rootState.PostingStore.ParticipantsStore.participantEmails = [];
        if (ccEmails?.length) {
          rootState.PostingStore.ParticipantsStore.participantsSelect = true;
          ccEmails.forEach((email) => {
            rootState.PostingStore.ParticipantsStore.participantEmails.push({
              email,
              error: false
            });
          });
        }
        const {confirmationPhones = []} = info.info;
        if (confirmationPhones?.length) {
          rootState.PostingStore.ParticipantsStore.participantsSelect = true;
          rootState.PostingStore.ParticipantsStore.participantPhones = confirmationPhones;
        }
        if (info.interpretationRequirement.confirmationBy) {
          rootState.PostingStore.ConfirmationByStore.confirmationBySelect = true;
          rootState.PostingStore.ConfirmationByStore.deadline = info.interpretationRequirement.confirmationBy;
        }
        rootState.PostingStore.ConfirmationByStore.confirmationByPhoneCheckbox = !!info.info.confirmationByPhone;
        const actualCreatedAt = info.actualCreatedAt || info.publishedAt || '';
        rootState.PostingStore.ReplacementStore.actualCreatedDate = actualCreatedAt ? moment(actualCreatedAt).format('YYYY-MM-DD') : '';
        rootState.PostingStore.ReplacementStore.actualCreatedTime = actualCreatedAt ? moment(actualCreatedAt).format('HH:mm') : '';
        rootState.PostingStore.ReplacementStore.bookingMechanism = info.bookingMechanism || 'phone';
        rootState.PostingStore.ManualFeesStore.manualFees.booking = info.info.manualBookingFeeApplied;
        rootState.PostingStore.ManualFeesStore.manualFees.editing = info.info.manualEditingFeeApplied;
        rootState.PostingStore.ManualFeesStore.manualFees.travelBooking = info.info.manualTravelBookingFeeApplied;
        // assignment information: advanced settings
        rootState.PostingStore.AccessibilityStore.accessibility = info.accessibility || 'private';
        rootState.PostingStore.QualificationStore.qualificationSelect = !!info.interpretationRequirement.specificQualificationRequested;
        rootState.PostingStore.QualificationStore.qualification = info.interpretationRequirement.qualificationId || '';
        rootState.PostingStore.ExtraInfoStore.extraInfo = info.info.description || '';
        rootState.PostingStore.ExtraInfoStore.extraInfoSelectState = !!info.info.description;
        if (info.attachments && info.attachments.length) {
          rootState.PostingStore.AttachmentsStore.attachmentsSelectState = true;
          rootState.PostingStore.AttachmentsStore.attachments = JSON.parse(JSON.stringify(info.attachments));
          rootState.PostingStore.AttachmentsStore.attachments.map((item) => {
            item.name = item.comment;
          });
        } else {
          rootState.PostingStore.AttachmentsStore.attachmentsSelectState = false;
        }
        rootState.PostingStore.SecretaryStore.secretaryContactName = info.info.secretaryContactName || '';
        rootState.PostingStore.SecretaryStore.secretaryContactPhone = info.info.secretaryContactPhoneNumber || '';
        rootState.PostingStore.SecretaryStore.secretaryContactPhoneCode = info.info.secretaryContactPhoneCode || '+47';
        rootState.PostingStore.SecretaryStore.secretaryInfo = info.info.secretaryInfo || '';
        rootState.PostingStore.SecretaryStore.secretaryCallbackPhone = info.info.secretaryCallbackPhoneNumber || '';
        rootState.PostingStore.SecretaryStore.secretaryCallbackPhoneCode = info.info.secretaryCallbackPhoneCode || '+47';
        rootState.PostingStore.SecretaryStore.secretaryCallbackPhoneSelect = !!info.info.secretaryCallbackPhoneNumber;
        // assignment information: in person form
        if (info.interpretationRequirement.contactAttributes && info.interpretationRequirement.contactAttributes.person) {
          rootState.PostingStore.AssignmentTypeStore.contactAttributes.person.name = info.interpretationRequirement.contactAttributes.person.name || '';
          rootState.PostingStore.AssignmentTypeStore.contactAttributes.person.phone = info.interpretationRequirement.contactAttributes.person.phone || '';
        }
        if (info.interpretationRequirement.contactAttributes && info.interpretationRequirement.contactAttributes.address) {
          const line1 = parsedDangerousData.line1 ?? info.interpretationRequirement.contactAttributes.address.line1;
          const postcode = parsedDangerousData.postcode ?? info.interpretationRequirement.contactAttributes.address.postcode;
          const city = parsedDangerousData.city ?? info.interpretationRequirement.contactAttributes.address.city;
          const country = parsedDangerousData.country ?? info.interpretationRequirement.contactAttributes.address.country;
          const county = parsedDangerousData.county ?? info.interpretationRequirement.contactAttributes.address.county;
          const latitude = parsedDangerousData.latitude ?? info.interpretationRequirement.contactAttributes.address.latitude;
          const longitude = parsedDangerousData.longitude ?? info.interpretationRequirement.contactAttributes.address.longitude;

          rootState.FullAddressStore.line1 = line1 || '';
          rootState.FullAddressStore.line2 = info.interpretationRequirement.contactAttributes.address.line2 || '';
          rootState.FullAddressStore.postcode = postcode || '';
          rootState.FullAddressStore.city = city || '';
          rootState.FullAddressStore.country = country || 'Norway';
          rootState.FullAddressStore.county = county || '';
          rootState.FullAddressStore.latitude = latitude || '';
          rootState.FullAddressStore.longitude = longitude || '';
          rootState.PostingStore.AssignmentTypeStore.contactAttributes.address.extra = info.interpretationRequirement.contactAttributes.address.extra || '';
        }
        // process requirements
        const processRequirement = info.processRequirement || {};
        const autoUpdateToSessionType = processRequirement.autoUpdateToSessionType;
        const sessionTypeProcessing = processRequirement.sessionTypeProcessing;
        rootState.PostingStore.ProcessRequirementStore.sessionTypeProcessing = sessionTypeProcessing;
        rootState.PostingStore.ProcessRequirementStore.autoUpdateToSessionType = autoUpdateToSessionType;
        // when auto update to video or phone
        if (autoUpdateToSessionType) rootState.PostingStore.ProcessRequirementStore.autoUpdateProcessingType = autoUpdateToSessionType;
        // auto update false cancel assignment
        else rootState.PostingStore.ProcessRequirementStore.autoUpdateProcessingType = sessionTypeProcessing;
        rootState.PostingStore.ProcessRequirementStore.directProcessing = processRequirement.directProcessing !== 'direct_no_action' ? processRequirement.directProcessing : '';
        rootState.PostingStore.ProcessRequirementStore.qualificationProcessing = processRequirement.qualificationProcessing;
        rootState.PostingStore.ProcessRequirementStore.genderProcessing = processRequirement.genderProcessing;
        rootState.PostingStore.AutoInviteStore.infiniteAutoInvite = processRequirement.infiniteAutoInvite;
        rootState.PostingStore.AutoInviteStore.autoInvite = processRequirement.autoInviteEnabled === 'on';
        rootState.PostingStore.AutoApproveStore.autoApprove = !!info.autoAwardAllowed;
        rootState.PostingStore.StandByStore.standBy = processRequirement.standbyProcessing === 'turned_on';
        // direct booking. Apply direct supplier only if account status is active
        if (info.direct && info.directInterUid && info.directInterStatus === 'active') {
          rootState.PostingStore.DirectBookingStore.directBookingSelected = true;
          rootState.PostingStore.DirectBookingStore.selectedSupplier = info.directInterUid;
        }
      },
      setForceCreate(state, value) {
        state.forceCreate = value;
      },
      cleareEditingData(state) {
        state.editingJob = false;
        state.urlEditingJob = '';
      },
      resetPostJobInfo(state) {
        state.disabledImportantFields = false;
        state.copiedJob = '';
        state.forceCreate = false;
        state.job = {
          job_id: '',
          status: '',
          interpretation_requirement: {
            interpreter_uid: '',
            direct_award: false
          },
        };
        state.trackType = '';
        state.existingEditJobInfo = false;
      }
    }
  };
};
